import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import BuyListComponent from '@a-type/ui/pages/lists/BuyList.component';
import { pageContentLoad, setList } from '@a-type/ui/stores/actions';
import { useGetListQuery } from '@a-type/ui/stores/apis';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const BuyMoreDataPage = () => {
  const dispatch = useDispatch();
  const { listId } = useParams<{ listId: string }>();
  const { data: list, isLoading: isListLoading } = useGetListQuery(listId!, {
    skip: !listId,
  });

  useEffect(() => {
    dispatch(pageContentLoad(!isListLoading));
  }, [isListLoading]);

  useEffect(() => {
    if (!list) return;

    dispatch(setList(list));
  }, [list]);

  return (
    <PageLayout container wide>
      <BuyListComponent />
    </PageLayout>
  );
};

export default BuyMoreDataPage;
