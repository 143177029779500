import { DataSourceFieldDataType, ListType } from '@a-type/enums';
import { ICount, IDataSource, IFilterModel } from '@a-type/interfaces';
import { useDispatch, useSelector } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import BuyListComponent from '@a-type/ui/pages/lists/BuyList.component';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { dataSourcesService } from '@a-type/ui/services';
import {
  pageContentLoad,
  resetList,
  setCurrentDataSource,
  setList,
  snackbarErrorMessage,
} from '@a-type/ui/stores/actions';
import { generateObjectId } from '@a-type/ui/utils';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const BuyNewListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { count, selectedNarrowSearch } = useSelector((state) => state.count);

  const getDataSourceById = async (id: string) => {
    const response = await dataSourcesService.getById(id);
    if (response?.status === 200) {
      dispatch(setCurrentDataSource({ ...response.data } as IDataSource));
      return response.data;
    }

    dispatch(snackbarErrorMessage('Data source not found'));
    navigate(AppRoutes.HomePage);
    return null;
  };

  const getAvailableRecords = async (
    dataSource: IDataSource,
    countModel: ICount,
  ): Promise<null | number> => {
    const response = await dataSourcesService.count(dataSource, countModel);
    if (response?.status === 200) {
      return response.data;
    }

    dispatch(snackbarErrorMessage('Error getting available records'));
    return null;
  };

  useEffect(() => {
    dispatch(resetList());
    dispatch(pageContentLoad(false));
    const locationArray = location.pathname.split('/');
    const countId = locationArray[locationArray.length - 1];

    if (!countId || !count) {
      dispatch(snackbarErrorMessage('Count not found'));
      navigate(AppRoutes.HomePage);
      dispatch(pageContentLoad(true));
    } else {
      const loadCount = async () => {
        if (count._id !== countId) {
          dispatch(snackbarErrorMessage('Count valid count opened'));
          navigate(AppRoutes.HomePage);
          dispatch(pageContentLoad(true));
          return;
        }

        let c = { ...count, filters: { ...(count.filters || {}) } } as ICount;

        // load data source
        const dataSource = await getDataSourceById(c.dataSourceId);
        if (!dataSource) {
          dispatch(snackbarErrorMessage('Data source not found'));
          navigate(AppRoutes.HomePage);
          dispatch(pageContentLoad(true));
          return;
        }

        if (selectedNarrowSearch.length > 0) {
          const field = dataSource.fields.find((f) => f.name === c.groupBy);
          if (!field) {
            dispatch(snackbarErrorMessage('Group by field not found'));
            navigate(AppRoutes.HomePage);
            dispatch(pageContentLoad(true));
            return;
          }

          const filter = c.filters[c.groupBy!];
          const values = selectedNarrowSearch.map((x: string) => {
            switch (field.dataType) {
              case DataSourceFieldDataType.NUMBER:
                return {
                  from: Number(x),
                  label: x,
                  to: Number(x),
                };
              case DataSourceFieldDataType.DATE:
                return {
                  from: x,
                  label: x,
                  to: x,
                };
              case DataSourceFieldDataType.STRING:
              case DataSourceFieldDataType.BOOLEAN:
              case DataSourceFieldDataType.ARRAY:
              default:
                return {
                  label: x,
                  value: x,
                };
            }
          });

          // create filter if not exists
          if (!filter) {
            c = {
              ...c,
              filters: {
                ...c.filters,
                [c.groupBy!]: {
                  _id: field.name,
                  mode: 'add',
                  name: field.displayName,
                  price: field.price,
                  type: field.dataType,
                  values: [...values],
                } as IFilterModel,
              },
            } as ICount;
          } else {
            c = {
              ...c,
              filters: {
                ...c.filters,
                [c.groupBy!]: {
                  ...c.filters[c.groupBy!],
                  values: Array.from(new Set([...filter.values, ...values])),
                },
              },
            } as ICount;
          }
        }

        // calculate new available records
        const availableRecords = await getAvailableRecords(dataSource, c);
        if (!availableRecords) {
          dispatch(snackbarErrorMessage('List not found'));
          navigate(AppRoutes.HomePage);
          dispatch(pageContentLoad(true));
          return;
        }

        // get fields metadata
        // include only fields that are in pricing plan or have filters
        const fields =
          dataSource.fields
            .filter((x) => {
              return (
                c.pricingPlanFields.some((y) => y === x.name) ||
                Object.values(c.filters).some(
                  (f) => f._id === x.name && f.values && f.values.length > 0,
                )
              );
            })
            .map((x) => x.name) || [];
        const fieldsMetadata = await dataSourcesService.fieldsMetadata(dataSource, fields);
        if (fieldsMetadata.status !== 200) {
          dispatch(snackbarErrorMessage('Error getting fields metadata'));
          navigate(AppRoutes.HomePage);
          dispatch(pageContentLoad(true));
          return;
        }

        dispatch(
          setList({
            ...c,
            _id: generateObjectId(),
            avilableRecords: availableRecords,
            countId: c._id,
            countName: c.name,
            fieldMetadata: fieldsMetadata.data,
            price: 0,
            targetType: dataSource.targetType,
            totalRecords: 0,
            type: ListType.LIST,
          }),
        );
        dispatch(pageContentLoad(true));
      };

      loadCount();
    }
  }, []);

  return (
    <PageLayout container wide>
      <BuyListComponent />
    </PageLayout>
  );
};

export default BuyNewListPage;
