import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import ListDocumentsComponent from '@a-type/ui/pages/lists/ListDocuments.component';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import { snackbarErrorMessage } from '@a-type/ui/stores/actions';
import { useGetListQuery } from '@a-type/ui/stores/apis';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ListDocumentsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listId } = useParams<{ listId: string }>();
  const { data: list, error: listError } = useGetListQuery(listId!, {
    skip: !listId,
  });

  useEffect(() => {
    if (listError) {
      dispatch(snackbarErrorMessage('List not found'));
      navigate(AppRoutes.HomePage);
    }
  }, [listError]);

  return (
    <PageLayout container wide>
      {list && <ListDocumentsComponent />}
    </PageLayout>
  );
};

export default ListDocumentsPage;
