import { ITaxAssessorShort } from '@a-type/dtos';
import { Dialog } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { PageLayout } from '@a-type/ui/layout';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useBuyRecordMutation,
  useGetIndividualSearchInfoQuery,
  useSearchTaxAssessorsQuery,
} from '@a-type/ui/stores/apis';
import globalStyles from '@a-type/ui/styles/global.styles';
import { getError } from '@a-type/ui/utils';
import { Box, CircularProgress, debounce, TextField, Typography } from '@mui/material';
import { APIProvider } from '@vis.gl/react-google-maps';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IndividualSearchCard } from './components/individual-search-card/individual-search-card';
import { IndividualSearchMap } from './components/individual-search-map/individual-search-map.component';

export const IndividualSearchPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState('');
  const [hoverPropertyId, setHoverPropertyId] = useState<string | undefined>(undefined);
  const [selectedProperty, setSelectedProperty] = useState<ITaxAssessorShort | undefined>(
    undefined,
  );
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const { data: individualSearch } = useGetIndividualSearchInfoQuery();
  const { data: searchResult, isFetching } = useSearchTaxAssessorsQuery({ query });
  const [properties, setProperties] = useState<ITaxAssessorShort[]>([]);
  const [notEnoughQuery, setNotEnoughQuery] = useState(false);
  const [buyRecord, { isLoading: isBuying }] = useBuyRecordMutation();

  useEffect(() => {
    dispatch(pageContentLoad(!isBuying));
  }, [isBuying]);

  const fetch = useMemo(
    () =>
      debounce((s: string) => {
        setQuery(s);
      }, 400),
    [],
  );

  useEffect(() => {
    if (search !== query) fetch(search);
  }, [search, fetch]);

  useEffect(() => {
    if (searchResult) {
      setProperties(searchResult.items);
      setNotEnoughQuery(searchResult.notEnoughQuery);
    }
  }, [searchResult]);

  const handleBuyRecord = async () => {
    if (selectedProperty) {
      const result = await buyRecord(selectedProperty.id);

      if (result.error) {
        dispatch(snackbarErrorMessage(getError(result.error) ?? 'An error occurred'));
      } else {
        dispatch(snackbarSuccessMessage('Property purchased successfully'));
        navigate(`${AppRoutes.Bucket}/tax-assessor/${result.data.documentId}`);
      }

      setShowPurchaseModal(false);
    }
  };

  return (
    <PageLayout container wide>
      <Box
        sx={{
          alignItems: 'baseline',
          borderBottom: `1px solid`,
          borderColor: 'gray.02',
          display: 'flex',
          gap: 2,
          mb: 2,
          pb: 1,
        }}
      >
        <Typography
          component="h1"
          sx={{
            color: 'gray.10',
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          Direct Property Search
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 2.5,
          height: 'calc(100vh - 278px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            flexGrow: 1,
            gap: 3,
          }}
        >
          <TextField
            fullWidth
            id="search"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by Address, City, Zip Code or State"
          />

          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY!} libraries={['marker']}>
            <IndividualSearchMap
              hoverPropertyId={hoverPropertyId}
              properties={properties || []}
              setHoverPropertyId={setHoverPropertyId}
            />
          </APIProvider>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              height: '100%',
              overflowX: 'hidden',
              overflowY: 'auto',
              px: 0.5,
              py: 0.25,
            }}
          >
            {!properties || properties.length === 0 ? (
              <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'gray.02',
                  borderRadius: '8px',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  component="span"
                  sx={{
                    color: 'gray.10',
                    fontSize: 20,
                    fontWeight: 400,
                    textAlign: 'center',
                    width: '275px',
                  }}
                >
                  {search.length === 0 && 'Start typing to search for properties'}

                  {search.length > 0 &&
                    notEnoughQuery &&
                    'Please type at least 5 characters to display results'}

                  {search.length > 0 && !notEnoughQuery && 'No results found'}
                </Typography>
              </Box>
            ) : (
              properties?.map((property) => (
                <IndividualSearchCard
                  buyRecord={() => {
                    setSelectedProperty(property);
                    setShowPurchaseModal(true);
                  }}
                  isHovered={hoverPropertyId === property.id}
                  key={property.id}
                  price={individualSearch?.price ?? 1}
                  property={property}
                  setHovered={(isHovered) =>
                    setHoverPropertyId(isHovered ? property.id : undefined)
                  }
                />
              ))
            )}
          </Box>

          {isFetching && (
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'gray.02',
                borderRadius: '8px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                opacity: 0.95,
                position: 'absolute',
                width: '100%',
              }}
            >
              <Typography
                component="span"
                sx={{
                  alignItems: 'center',
                  color: 'gray.10',
                  display: 'flex',
                  fontSize: 20,
                  fontWeight: 400,
                  gap: 4,
                  justifyContent: 'center',
                  textAlign: 'center',
                  width: '275px',
                }}
              >
                <CircularProgress
                  size={48}
                  sx={{
                    color: 'blue.05',
                  }}
                  thickness={2}
                />
                Loading...
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {showPurchaseModal && selectedProperty && (
        <Dialog
          cancelText="Cancel"
          okText="Purchase"
          onCancel={() => setShowPurchaseModal(false)}
          onClose={() => setShowPurchaseModal(false)}
          onOk={handleBuyRecord}
          open={showPurchaseModal}
          size="md"
          title="Purchase Confirmation"
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 400,
            }}
          >
            Do you confirm the purchase <b>{selectedProperty.address}</b> <br />
            for{' '}
            <Typography
              component="span"
              sx={{
                color: globalStyles.mainColors.greenColor,
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {individualSearch?.price} Credit(s)
            </Typography>
            ?
          </Typography>
        </Dialog>
      )}
    </PageLayout>
  );
};
