import { IUpdateViewBasic } from '@a-type/dtos';
import { CombinedSearchType, TargetType } from '@a-type/enums';
import { IBaseLookup, ICombinedSearch, IView } from '@a-type/interfaces';
import { FormInput, FormSelect, ImageUpload } from '@a-type/ui/components';
import { useDispatch } from '@a-type/ui/hooks';
import { AppRoutes } from '@a-type/ui/router/AppRoutes';
import {
  pageContentLoad,
  snackbarErrorMessage,
  snackbarSuccessMessage,
} from '@a-type/ui/stores/actions';
import {
  useCreateViewMutation,
  useGetCombinedSearchesQuery,
  useUpdateViewBasicMutation,
  useUpdateViewImageMutation,
} from '@a-type/ui/stores/apis';
import { generateObjectId, getError } from '@a-type/ui/utils';
import { Box, Button, useTheme } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ViewSteps } from './view-steps.enum';

interface IViewBasicProps {
  setActiveStep: (step: ViewSteps) => void;
  setUpdateConfirmation: (value: { nextStep: string; show: boolean }) => void;
  view: IView | null;
}

const Targets: IBaseLookup[] = [
  { label: 'Residential', value: TargetType.CUSTOMER },
  { label: 'Business', value: TargetType.BUSINESS },
];

export const ViewBasic = forwardRef(
  ({ setActiveStep, setUpdateConfirmation, view }: IViewBasicProps, ref) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: combinedSearchesData, isLoading: isCombinedSearchesLoading } =
      useGetCombinedSearchesQuery();
    const [createView, { isLoading: isCreatingView }] = useCreateViewMutation();
    const [updateView, { isLoading: isUpdatingView }] = useUpdateViewBasicMutation();
    const [updateImage, { isLoading: isUpdatingImage }] = useUpdateViewImageMutation();
    const [combinedSearches, setCombinedSearches] = useState<ICombinedSearch[]>([]);
    const { control, getValues, handleSubmit, setValue } = useForm<IUpdateViewBasic>({
      defaultValues: {
        _id: generateObjectId(),
        name: '',
        targetType: TargetType.CUSTOMER,
        type: CombinedSearchType.ATTOM,
      },
    });
    const [originalValues, setOriginalValues] = useState<IUpdateViewBasic | null>(null);
    const theme = useTheme();

    useEffect(() => {
      dispatch(
        pageContentLoad(
          !isCombinedSearchesLoading && !isCreatingView && !isUpdatingView && !isUpdatingImage,
        ),
      );
    }, [isCombinedSearchesLoading, isCreatingView, isUpdatingView, isUpdatingImage]);

    useEffect(() => {
      if (combinedSearchesData) {
        setCombinedSearches(combinedSearchesData);
      }
    }, [combinedSearchesData]);

    useEffect(() => {
      if (view) {
        const { _id, description, name, targetType, type } = view;

        const initialValues: IUpdateViewBasic = {
          _id,
          description,
          name,
          targetType,
          type,
        };

        setOriginalValues(initialValues);

        setValue('_id', _id);
        setValue('type', type);
        setValue('name', name);
        setValue('targetType', targetType);
        setValue('description', description);
      }
    }, [view]);

    const handleUpdateImage = async (url: string) => {
      if (!view) return;

      const result = await updateImage({ id: view._id, imageUrl: url });

      if (result.data) {
        dispatch(snackbarSuccessMessage('Image uploaded successfully'));
      }

      if (result.error) {
        dispatch(snackbarErrorMessage(getError(result.error) ?? 'Error while updating image'));
      }
    };

    const hasValueChanged = () => {
      if (!originalValues) return false;

      const currentValues = getValues();

      return (
        currentValues.name !== originalValues.name ||
        currentValues.description !== originalValues.description ||
        currentValues.targetType !== originalValues.targetType ||
        currentValues.type !== originalValues.type
      );
    };

    const handleUpdate = async () => {
      const result = view ? await updateView(getValues()) : await createView(getValues());

      if (result.data) {
        dispatch(
          snackbarSuccessMessage(view ? 'View updated successfully' : 'View created successfully'),
        );

        if (!view) {
          navigate(`${AppRoutes.AdminViewsPage}/${result.data._id}`);
        }
        return true;
      }

      if (result.error) {
        dispatch(
          snackbarErrorMessage(getError(result.error) ?? 'Error while updating combined search'),
        );
        return false;
      }

      return false;
    };

    useImperativeHandle(ref, () => ({
      handleUpdate,
      hasUnsavedChanges: hasValueChanged,
    }));

    const handleContinue = () => {
      if (hasValueChanged()) {
        setUpdateConfirmation({ nextStep: ViewSteps.Filters, show: true });
      } else {
        setActiveStep(ViewSteps.Filters);
      }
    };

    return (
      <Box
        component="form"
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            height: 'auto',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 3.5, minWidth: 400 }}
          >
            <FormInput control={control} label="Title" maxLength={50} name="name" required />

            {combinedSearches.length > 0 && (
              <FormSelect
                control={control}
                disabled={view !== null}
                getOptionKey={(option) => option.type}
                getOptionLabel={(option) => option.name}
                label="Combined Search"
                name="type"
                options={combinedSearches}
                required
              />
            )}

            <FormInput
              control={control}
              label="Description"
              maxLength={500}
              multiline
              name="description"
              rows={7}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3.5,
              minWidth: 400,
            }}
          >
            <FormSelect
              control={control}
              getOptionKey={(option) => option.value}
              getOptionLabel={(option) => option.label}
              label="Target Type"
              name="targetType"
              options={Targets}
              required
            />

            <Box
              sx={{
                backgroundImage: `url(${view?.imageUrl})`,
                backgroundSize: 'cover',
                border: `1px solid ${theme.palette.gray['03']}`,
                borderRadius: 2,
                height: 300,
                position: 'relative',
                width: 400,
              }}
            >
              <ImageUpload
                disabled={!view}
                height={300}
                key={`${view?._id}`}
                name={`${view?._id}`}
                sx={{
                  bottom: 4,
                  position: 'absolute',
                  right: 4,
                }}
                updateImage={handleUpdateImage}
                width={400}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'flex-end',
          }}
        >
          {view && (
            <>
              <Button
                color="primary"
                onClick={handleSubmit(handleUpdate)}
                sx={{ minWidth: 120 }}
                variant="contained"
              >
                Update
              </Button>
              <Button color="primary" onClick={handleContinue} variant="outlined">
                Continue to Filters
              </Button>
            </>
          )}

          {!view && (
            <Button
              color="primary"
              onClick={handleSubmit(handleUpdate)}
              sx={{ minWidth: 120 }}
              variant="contained"
            >
              Create
            </Button>
          )}
        </Box>
      </Box>
    );
  },
);

ViewBasic.displayName = 'ViewBasic';
