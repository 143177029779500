import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { ChangeEvent } from 'react';

interface NumericTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  inputProps?: {
    max?: number;
    min?: number;
    step?: number;
  };
  onChange: (value: number | string) => void;
  value: number | string;
}

const NumericTextField: React.FC<NumericTextFieldProps> = ({
  inputProps = {},
  onChange,
  value,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const min = inputProps.min ?? 0;
    const max = inputProps.max ?? Infinity;

    const numericValue =
      inputValue === '' ? '' : Math.min(Math.max(Number(inputValue) || 0, min), max);

    onChange(numericValue);
  };

  const placeholder = inputProps.min?.toString() ?? '0';

  return (
    <TextField
      {...props}
      inputProps={{ ...inputProps }}
      onChange={handleChange}
      placeholder={placeholder}
      type="number"
      value={value === 0 ? '' : value}
    />
  );
};

export default NumericTextField;
