import { PageLayout } from '@a-type/ui/layout';
import CountsListComponent from '@a-type/ui/pages/counts/CountsList.component';

export const CountsListPage = () => {
  return (
    <PageLayout container wide>
      <CountsListComponent />
    </PageLayout>
  );
};

export default CountsListPage;
